<template>
     <KCourse loader-class="MBcont" course-id="0" title="" :items="items">
         <div class="MBtextcont">
            <h1>Как сдавать домашнее задание?</h1>
            <p>Если вы хотите получить конструктивный разбор вашей работы, прочитайте пожалуйста, как нужно ее фотографировать. </p>

            <h2>1. Сделайте контрастный фон</h2>
            <p>Если это белые детали, то лучше подобрать контрастный фон (не белый, не черный) </p>
         </div>

         <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/Homework/1.jpg')" alt="" width="100%"/></div>

         <div class="MBtextcont">
            <h2>2. Фотографируйте с 2-3 ракурсов на уровне детали</h2>
            <p>Фото должно быть сделано как минимум с 2, а лучше с 3-4 сторон (вид спереди, вид сбоку (если необходимо с двух боков), вид сзади. </p>
            <p>На деталях <b>со вставленными шарнирами должна быть карандашная разметка </b> шарика и детали спереди, сбоку и сзади. </p>
         </div>

         <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/Homework/2.jpg')" alt="" width="100%"/></div>

         <div class="MBtextcont">
            <p><b>Фотографировать нужно на уровне детали,</b> если она стоит или вы ее держите в руке. Или прямо над ней, если она лежит. Избегайте ракурсов сверху, снизу и сбоку. </p>
         </div>

         <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/Homework/1.jpg')" alt="" width="100%"/></div>

         <div class="MBtextcont">
            <p><i>Чтобы деталь устойчиво лежала в нужном ракурсе, воспользуйтесь кусочками пластилина для ее фиксации на поверхности стола</i></p>
            <h2>3. Освещайте деталь равномерно (выше показан пример)</h2>
            <p>Свет должен падать прямо на деталь, а не сбоку особенно это касается лица.</p>

            <h2>4. Вставляйте деталь в трафарет</h2>
            <p> Если вы показываете заготовку детали из пластилина или из пластика, то вставляем ее сразу в трафарет и фотографируем. </p>
         </div>

         <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/Homework/3.jpg')" alt="" width="100%"/></div>

         <div class="MBtextcont"><p>Жду ваших работ )</p></div>
     </KCourse>
</template>

<script>
    import KCourse from "../../Widgets/KCourse";
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            KCourse,
            KImage
        },
        data() {
            return {
                items: []
            }
        }
    }
</script>

<style scoped>
    h1 {
        line-height: 1;
        margin-top: 0;
        text-align: left;
    }
    h2 {
        margin-top: 2em;
    }
</style>